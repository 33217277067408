export default {
	header: {
		self: {},
		items: [
			{
			},
		]
	},
	aside: {
		self: {},
		items: [
			{
				title: "Dashboard",
				root: true,
				icon: "fas fa-tachometer-alt",
				page: "dashboard",
				bullet: "dot"
			},
			{
				title: "Recência X Frequência",
				root: true,
				icon: "fas fa-chart-bar",
				page: "recencia-frequencia",
				bullet: "dot"
			},
			{
				title: "Empresas",
				root: true,
				icon: "fas fa-building",
				page: "empresa",
				bullet: "dot"
			},
			{
				title: "Acesso usuários do Pote",
				root: true,
				icon: "fas fa-users",
				page: "pote",
				bullet: "dot"
			},
			{
				title: "Usuários",
				root: true,
				icon: "fas fa-user",
				page: "usuario",
				bullet: "dot"
			},
			{
				title: "Comunicados",
				root: true,
				icon: "fas fa-comment",
				page: "comunicado",
				bullet: "dot"
			},
			{
				title: "Customer Success",
				root: true,
				icon: "fas fa-chart-line",
				page: "customer-success",
				bullet: "dot",
				submenu: [
					{
						title: "Report Empresa",
						page: "customer-success/report-empresa",
						bullet: "dot"
					},
					{
						title: "Uso por agendamento",
						page: "customer-success/agendamento-uso",
						bullet: "dot"
					},
				]
			},
			{
				title: "Suporte",
				root: true,
				icon: "fas fa-headset",
				page: "guru",
				bullet: "dot",
				submenu: [
					{
						title: "Clientes",
						page: "suporte/clientes",
						bullet: "dot"
					},
					{
						title: "Guru ",
						page: "suporte/guru",
						bullet: "dot"
					},
					{
						title: "Usuários ",
						page: "suporte/empresa-usuarios",
						bullet: "dot"
					},
				]
			},
			{
				title: "Importações",
				root: true,
				icon: "fas fa-download",
				page: "guru",
				bullet: "dot",
				submenu: [
					{
						title: "Clientes",
						page: "importacao/clientes",
						bullet: "dot"
					},
					{
						title: "Profissionais",
						page: "importacao/profissionais",
						bullet: "dot"
					},
					{
						title: "Serviços",
						page: "importacao/servicos",
						bullet: "dot"
					},
					{
						title: "Produtos",
						page: "importacao/produtos",
						bullet: "dot"
					},
				]
			},
			{
				title: "Integração Guru",
				root: true,
				icon: "fas fa-user",
				page: "guru",
				bullet: "dot",
				submenu: [
					{
						title: "Token",
						page: "guru-token",
						bullet: "dot"
					},
					{
						title: "Clientes Guru",
						page: "guru-cliente",
						bullet: "dot"
					},
					{
						title: "Erros WebHook",
						page: "guru-webhook-error-log",
						bullet: "dot"
					}
				]
			},
			{
				title: "Relatórios",
				root: true,
				bullet: "dot",
				icon: "fas fa-chart-pie",
				submenu: [
					{
						title: "Empresas",
						bullet: "dot",
						page: "relatorio/relatorio01"
					},
					{
						title: "Erros Hook Guru",
						bullet: "dot",
						page: "relatorio/relatorio02"
					},
					{
						title: "Movimentações por empresa",
						bullet: "dot",
						page: "relatorio/relatorio03"
					},
				]
			},
			{
				title: "Grupos de Acesso",
				root: true,
				icon: "fas fa-layer-group",
				page: "grupo-permissao",
				bullet: "dot"
			},
		]
	}
};
